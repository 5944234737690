import React from 'react';
import { Alert, } from 'reactstrap';

function MessageAlerts({ messages }) {

    
    if (messages.length === 0) {
        return <div className='mb-3' />;
    }

    return (
        <div>
            {
                messages.map((m, i) => {
                    return <Alert className={'text-center ' + ((i < messages.length-1) ? 'no-margin-bottom' : '') } key={i} color={m.color}>{m.text}</Alert>;
                })
            }
        </div>
    );   
        
    
}
export { MessageAlerts };