import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, FormGroup, Label, Input, Modal, ModalBody, ModalHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import DatePicker from "react-datepicker";
import { BeatLoader } from 'react-spinners';
import queryString from 'query-string';

import authService from './api-authorization/AuthorizeService';
import AdminFetchComponent from './AdminFetchComponent';
import GroupCustomerFetchComponent from './GroupCustomerFetchComponent';

function ContainersPage({ role, customerNo, setCustomerNo, rentalObjectsCustomerNo, setRentalObjectsCustomerNo, rentalObjects, setRentalObjects }) {

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [fetchError, setFetchError] = useState('');

    const fetchRentalObjects = useCallback(async () => {
        if (authService.isManagerOrAdminOrGroupCustomerRole(role) && customerNo === '') {
            return;
        }
        setFetching(true);
        const token = await authService.getAccessToken();

        const managerCustomerNumber = authService.isManagerOrAdminOrGroupCustomerRole(role) ? `/${customerNo}` : '';
        const groupCustomer = authService.isGroupCustomerRole(role) ? 'GroupCustomer' : '';
        const url = `api/Customer/RentalObjects${groupCustomer}${managerCustomerNumber}`;
        console.log("url: " + url)

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                throw new Error('Anrop misslyckades');
            }
            const data = await response.json();
            setRentalObjects(data);
        } catch (error) {
            setFetchError(error.message);
        }
        setFetching(false);
        setRentalObjectsCustomerNo(customerNo);
        setFetched(true);
    }, [role, customerNo, setRentalObjects, setRentalObjectsCustomerNo])

    useEffect(() => {
        const customerNeedsFetch = (rentalObjects === null && authService.isCustomerRole(role));
        const adminNeedsFetch = (authService.isManagerOrAdminOrGroupCustomerRole(role) && customerNo !== null && customerNo !== rentalObjectsCustomerNo);
        if (customerNeedsFetch || adminNeedsFetch) {
            fetchRentalObjects();
        }
    }, [fetchRentalObjects, customerNo, rentalObjectsCustomerNo, role, rentalObjects])

/* DEBUGGING AID 
    const compareInputs = (inputKeys, oldInputs, newInputs) => {
        inputKeys.forEach(key => {
            const oldInput = oldInputs[key];
            const newInput = newInputs[key];
            if (oldInput !== newInput) {
                console.log("change detected", key, "old:", oldInput, "new:", newInput);
            }
        });
    };
    const useDependenciesDebugger = inputs => {
        const oldInputsRef = useRef(inputs);
        const inputValuesArray = Object.values(inputs);
        const inputKeysArray = Object.keys(inputs);
        useMemo(() => {
            const oldInputs = oldInputsRef.current;

            compareInputs(inputKeysArray, oldInputs, inputs);

            oldInputsRef.current = inputs;
        }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
    };

    useDependenciesDebugger({ fetchRentalObjects, role, rentalObjects });
 END DEBUGGING AID */

    const noRentalObjectsFound = !fetching
        && rentalObjects !== null
        && rentalObjects.length === 0
        && fetched;

    return (
      <div>
        <h1>Containrar</h1>

            <div>
                {authService.isManagerOrAdminRole(role)
                    && <AdminFetchComponent
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo} />
                }

                {authService.isGroupCustomerRole(role)
                    && <GroupCustomerFetchComponent
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo} />
                }

                {fetching
                    ? <div className='margin-top-bottom'>Hämtar containrar...<BeatLoader size={15} margin={2} /></div>
                    : <div>
                        {rentalObjects !== null && rentalObjects.length > 0 &&
                            <div className='margin-top margin-bottom-medium'>
                                <h4>{rentalObjects[0].customerName}</h4>
                            </div>
                        }
                        <ActiveOrdersListComponent
                            rentalObjects={rentalObjects}
                            role={role}
                            customerNo={customerNo}
                        />
                        <ContainerListComponent
                            rentalObjects={rentalObjects}
                            role={role}
                            customerNo={customerNo}
                        />
                      </div>
                }
                {noRentalObjectsFound && !fetchError && <div className='margin-top-bottom'>Inga containrar hittades.</div>}
                {fetchError && <div className='margin-top-bottom'>{fetchError}</div>}
            </div>
      </div>
    );
}

function ActiveOrdersListComponent({ rentalObjects, role, customerNo }) {
    // Hämtdatum
    // Beställningsdatum
    // Status
    // Beskrivning av container
    const activeOrders = rentalObjects === null ? [] : rentalObjects.filter(r => r.orderMessageId);
    return (
        <div className='margin-top-bottom'>
            {activeOrders.length > 0 &&
                <div className='margin-bottom-medium'>
                    <div className='margin-bottom'>
                        <h5 className='font-bold'>Aktiva beställningar</h5>
                    </div>
                    <ListGroup>
                    {activeOrders.map(r => <ActiveOrderComponent role={role} key={r.bookingNumber} rentalObject={r} customerNo={customerNo} />)}
                    </ListGroup>
                </div>
            }
        </div>
    );
}

function ContainerListComponent({ rentalObjects, role, customerNo }) {
    return (
        <div className='margin-top-bottom'>
            {rentalObjects !== null && rentalObjects.length > 0 &&
                <div className='margin-bottom-medium'>
                    <div className='margin-bottom'>
                        <h5 className='font-bold'>Innehav</h5>
                    </div>
                    <ListGroup>
                        {rentalObjects.map(r => <ContainerComponent role={role} key={r.bookingNumber} rentalObject={r} customerNo={customerNo} />)}
                    </ListGroup>
                </div>
            }
        </div>
    );
}

function ActiveOrderComponent({ role, rentalObject: r, customerNo }) {

    const missingData = '';
    const messageId = r.orderMessageId;
    const [rentalObjectStatus, setRentalObjectStatus] = useState(null);
    const [checkStatusCounter, setCheckStatusCounter] = useState(0);
    const [expanded, setExpanded] = useState(true);

    const fetchRentalObjectStatus = useCallback(async () => {
        const token = await authService.getAccessToken();

        const manager = authService.isManagerOrAdminOrGroupCustomerRole(role) ? `Manager` : '';
        const groupCustomer = authService.isGroupCustomerRole(role) ? `GroupCustomer` : '';
        const queryStr = { messageId };
        if (authService.isGroupCustomerRole(role)) {
            queryStr.customerNumber = customerNo;
        }
        const url = `api/Customer/RentalObjectStatus${manager}${groupCustomer}?${queryString.stringify(queryStr)}`;

        const response = await fetch(url, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setRentalObjectStatus(data);
    }, [role, messageId, customerNo])

    useEffect(() => {
        if (messageId) {
            fetchRentalObjectStatus();
        }
    }, [fetchRentalObjectStatus, messageId, customerNo, checkStatusCounter])

    useEffect(() => {
        const timer = setInterval(() => {
            setCheckStatusCounter(checkStatusCounter + 1);
        }, 15 * 1000);
        return () => clearInterval(timer);
    });

    function checkEmpty(str, replacement) {
        if (!str || /^\s*$/.test(str)) {
            return replacement
        }
        return str;
    }

    function handleClick(e) {
        e.preventDefault();
        setExpanded(!expanded);
    }

    return (
        <ListGroupItem >
            <a href='/#' onClick={handleClick}>
                <Row>
                    <Col>Plats: {r.locationAddress}</Col>
                    <Col xs={2}>Typ: {checkEmpty(r.objectType, missingData)}</Col>
                    <Col>Material: {checkEmpty(r.renBookingObjectContentDescr, missingData)}</Col>
                </Row>
            </a>
            {expanded &&
                <div className='margin-top'>
                    <Row>
                        <Col xs={3}>Postadress: {checkEmpty(r.locationPostaddress, missingData)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Postnr: {checkEmpty(r.locationPostCode, missingData)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Hyresavtal: {r.bookingNumber}</Col>
                    </Row>
                    <OrderStatusComponent r={r} rentalObjectStatus={rentalObjectStatus} />
                </div>
            }
        </ListGroupItem>
    )
}

function ContainerComponent({ role, rentalObject: r }) {

    const missingData = '';
    const [showOrderModal, setShowOrderModal] = useState(false);
    const toggle = () => setShowOrderModal(!showOrderModal);
    const [expanded, setExpanded] = useState(false);

    const updateOrder = order => {
        r.orderStatus = order.status;
        r.orderMessageId = order.messageId;
        r.orderDate = order.date;
        r.action = order.action;
    }

    function checkEmpty(str, replacement) {
        if (!str || /^\s*$/.test(str)) {
            return replacement
        }
        return str;
    }

    function handleClick(e) {
        e.preventDefault();
        setExpanded(!expanded);
    }

    return (
        <ListGroupItem >
            <a href='/#' onClick={handleClick}>
                <Row>
                    <Col>Plats: {r.locationAddress}</Col>
                    <Col xs={2}>Typ: {checkEmpty(r.objectType, missingData)}</Col>
                    <Col>Material: {checkEmpty(r.renBookingObjectContentDescr, missingData)}</Col>
                </Row>
            </a>
            {expanded &&
                <div className='margin-top'>
                    <Row>
                        <Col xs={3}>Postadress: {checkEmpty(r.locationPostaddress, missingData)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Postnr: {checkEmpty(r.locationPostCode, missingData)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Hyresavtal: {r.bookingNumber}</Col>
                    </Row>
                    {!r.orderMessageId &&
                        <Button className='margin-top' disabled={showOrderModal || r.canOrder !== null} onClick={() => setShowOrderModal(true)}>Beställ tömning/återtagande</Button>
                    }

                    {authService.isManagerOrAdminRole(role) && r.canOrder !== null && <div>({r.canOrder})</div>}
                </div>
            }
            {showOrderModal &&
                <CreateOrderModal rentalObject={r} isOpen={showOrderModal} toggle={toggle} updateOrder={updateOrder} />
            }
        </ListGroupItem>
    )
}

function OrderStatusComponent({ r, rentalObjectStatus }) {

    const action = { Hemtagning: "Återtagande", Tomning: "Tömning" };
    if (!r.orderMessageId) {
        return null;
    }
    const received = rentalObjectStatus && rentalObjectStatus.orderNumber;
    return (
        <div className='rental-object-status'>
            <Row>
                <Col xs={6}>Status: {received ? rentalObjectStatus.status : r.OrderStatusText ? r.OrderStatusText : 'Inskickad'}</Col>
                <Col xs={6}>Åtgärd: {action[r.action]}</Col>
            </Row>
            <Row>
                <Col xs={6}>Namn på beställare: {r.orderBookingPerson}</Col>
                <Col xs={6}>Meddelande: {r.orderMessage}</Col>
            </Row>
            {
                !received &&
                <>
                    <Row>
                        <Col xs={6}>Hämtdatum: {r.orderDate.substring(0, 10)}</Col>
                        <Col xs={6}>Beställningsdatum: {r.orderCreatedDate.substring(0, 10)}</Col>
                    </Row>
                </>
            }
            {
                received &&
                <>
                    <Row>
                        <Col xs={6}>Hämtdatum: {rentalObjectStatus.pickupDate?.substring(0, 10)}</Col>
                        <Col xs={6}>Beställningsdatum: {r.orderCreatedDate.substring(0, 10)}</Col>
                    </Row>
                    <Row>
                        <Col xs={6}>Ordernr: {rentalObjectStatus.orderNumber}</Col>
                    </Row>
                </>
            }
        </div>
    );
}

function ConditionalWrapper({ condition, wrapper, children }) {
    return condition ? wrapper(children) : children;
}

function CreateOrderModal({ rentalObject: r, isOpen, toggle, updateOrder }) {

    const getValidDates = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr.filter(x => x.getDay() > 0 && x.getDay() < 6); // Remove Sunday och Saturday
    }

    const now = new Date();
    const earliestDate = new Date();
    earliestDate.setDate(now.getDate() + 1);
    if (now.getHours() >= 12) {
        earliestDate.setDate(now.getDate() + 2);
    }
    const oneweek = now;
    oneweek.setDate(now.getDate() + 7);
    const includeDates = getValidDates(earliestDate, oneweek);

    const [action, setAction] = useState('Tomning');
    const [date, setDate] = useState(earliestDate);
    const [notification, setNotification] = useState(false);
    const [orderClicked, setOrderClicked] = useState(false);
    const [orderDone, setOrderDone] = useState(false);
    const [orderError, setOrderError] = useState(null);
    const [message, setMessage] = useState('');
    const [bookingPerson, setBookingPerson] = useState('');

    const getDateString = date => {
        if (date === null || date === '') {
            return null;
        }
        const offsetMs = date.getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(date.getTime() - offsetMs);
        const str = dateLocal.toISOString().slice(0, 19);
        return str;
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setOrderClicked(true);

        const token = await authService.getAccessToken();
        const newOrder = { action, date: getDateString(date), bookingNumber: r.bookingNumber, notification, message, bookingPerson };
        try {
            const response = await fetch('api/Customer/CreateOrder', {
                method: 'POST',
                body: JSON.stringify(newOrder),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
                throw new Error(data.result);
            }
            setOrderDone(true);
            setOrderError(null);
            updateOrder(data.object);
        } catch (error) {
            setOrderError(error.message);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{r.locationAddress} - {r.objectType} - {r.renBookingObjectContentDescr}</ModalHeader>

            <ModalBody>
                {!orderDone && !orderError &&
                <Form onSubmit={e => handleSubmit(e, r.bookingNumber, action, date)}>

                    <FormGroup>
                        <Label for="addAction">Åtgärd:</Label>
                        <Input
                            type="select"
                            name="select"
                            value={action}
                            onChange={e => setAction(e.target.value)}
                            id="addAction">
                            <option value={'Tomning'}>Tömning</option>
                            <option value={'Hemtagning'}>Återtagande</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="date">Datum:</Label>
                        <DatePicker
                            className='form-control'
                            id="date"
                            selected={date}
                            includeDates={includeDates}
                            onChange={date => setDate(date)}
                            dateFormat='yyyy-MM-dd'
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="addAction">Namn på beställare:</Label>
                        <Input
                            required
                            maxLength={100}
                            type="text"
                            name="addBookingPerson"
                            value={bookingPerson}
                            onChange={e => setBookingPerson(e.target.value)}
                            id="addBookingPerson" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="addAction">Meddelande till Lundstams (frivilligt):</Label>
                        <Input
                            maxLength={100}
                            type="text"
                            name="addMessage"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            id="addMessage" />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox"
                                checked={notification}
                                onChange={e => setNotification(e.target.checked)} />{' '}
                            <ConditionalWrapper
                                condition={!notification}
                                wrapper={children => <s>{children}</s>} >
                                Vi hör av oss samma dag inför att ordern ska utföras så att ni vet när vi kommer
                            </ConditionalWrapper>
                        </Label>
                    </FormGroup>


                    <Button className="margin-top" disabled={orderClicked}>Skicka</Button>
                    {orderClicked && !orderDone && !orderError &&
                        <div className='margin-top-bottom'><BeatLoader size={15} margin={2} /></div>
                    }
                </Form>
                }
                {orderDone && !orderError &&
                    <div>Beställning har skickats</div>
                }
                {orderError &&
                    <Alert color="danger">{orderError}</Alert>
                }
                {(orderDone || orderError) &&
                    <Button className='margin-top' onClick={toggle}>Stäng</Button>
                }
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        rentalObjects: state.rentalObjects,
        customerNo: state.customerNo,
        rentalObjectsCustomerNo: state.rentalObjectsCustomerNo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRentalObjects: (data) => dispatch({ type: 'RENTAL_OBJECTS', data }),
        setCustomerNo: (data) => dispatch({ type: 'CUSTOMER_NO', data }),
        setRentalObjectsCustomerNo: (data) => dispatch({ type: 'RENTAL_OBJECTS_CUSTOMER_NO', data }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainersPage);
