import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { BeatLoader } from 'react-spinners';
import queryString from 'query-string';

import authService from './api-authorization/AuthorizeService';


function MessagesPage({ setMessageAlerts }) {

    const colors = [{ value: 'primary', text: 'Blå' }, { value: 'success', text: 'Grön' }, { value: 'secondary', text: 'Grå' }, { value: 'warning', text: 'Gul' }, { value: 'danger', text: 'Röd' }];
    const [messages, setMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [text, setText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [color, setColor] = useState(colors[0].value);
    const [messageAdded, setMessageAdded] = useState('');
    const [messageAddedError, setMessageAddedError] = useState('');


    const fetchMessages = useCallback(async () => {
        setLoadingMessages(true);
        const token = await authService.getAccessToken();
        const response = await fetch('api/Manager/GetMessages', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setMessages(data);
        setMessageAlerts(data);
        setLoadingMessages(false);
    }, [setMessageAlerts])

    useEffect(() => {
        fetchMessages()
    }, [fetchMessages])

    const getDateString = date => {
        if (date === null || date === '') {
            return null;
        }
        const offsetMs = date.getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(date.getTime() - offsetMs);
        const str = dateLocal.toISOString().slice(0, 10);
        console.log(date + " _-- " + str);
        return str;
    }

    const handleSubmit = async (e, ) => {
        e.preventDefault();

        const token = await authService.getAccessToken();
        const newMsg = { text, color, startDate: getDateString(startDate), endDate: getDateString(endDate) };
        try {
            const response = await fetch('api/Manager/AddNewMessage', {
                method: 'POST',
                body: JSON.stringify(newMsg),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
                throw new Error(data.result);
            }
            setMessageAdded(data.object);
            setMessageAddedError(null);
            setText('');
            setStartDate('');
            setEndDate('');

            fetchMessages();
        } catch (error) {
            setMessageAdded('');
            setMessageAddedError(error.message);
        }
    }

    const deleteMessage = async id => {
        const token = await authService.getAccessToken();
        const reportQuery = { id };
        const url = `api/Manager/DeleteMessage?${queryString.stringify(reportQuery)}`;

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
                throw new Error(data.result);
            }

            fetchMessages();
            console.log(`Message deleted ok ${id}`)
        } catch (error) {
            console.log(`Message delete failed ${error.message}`)
        }
    }

    return (
        <div>
            <h1>Meddelanden</h1>


            <h4>Lägg till meddelande</h4>
            <div>
                <Form onSubmit={handleSubmit}>

                    <FormGroup>
                        <Label for="addText">Meddelande:</Label>
                        <Input
                            required
                            type="text"
                            name="text"
                            value={text}
                            onChange={e => setText(e.target.value)}
                            id="addText"
                            placeholder="" />
                    </FormGroup>

                    <FormGroup>
                        <Label for="startDate">Startdatum:</Label>
                        <DatePicker
                            className='form-control'
                            id="startDate"
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat='yyyy-MM-dd'
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="endDate">Slutdatum:</Label>
                        <DatePicker
                            className='form-control'
                            id="endDate"
                            selected={endDate}
                            minDate={startDate}
                            onChange={date => setEndDate(date)}
                            dateFormat='yyyy-MM-dd'
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="report">Färg:</Label>
                        <Input
                            type="select"
                            name="select"
                            value={color}
                            onChange={e => setColor(e.target.value)}
                            id="report">
                            {colors.map(c => <option key={c.value} value={c.value}>{c.text}</option>)}
                        </Input>
                    </FormGroup>
                    {text !== '' &&
                        <Alert color={color}>{text}</Alert>
                    }
                    <Button>Spara</Button>
                </Form>
                {messageAdded && <div>Meddelande "{messageAdded}" har sparats.</div>}
                {messageAddedError && <div>{messageAddedError}</div>}
            </div>

            <div className='vertical-space' />

            <h4>Aktuella meddelanden</h4>
            <div>
                {
                    loadingMessages
                        ? <BeatLoader size={15} margin={2} />
                        : messages.map(m => <Alert className='overflow-hidden' key={m.id} color={m.color}>{m.text}<Button className='float-right' onClick={() => deleteMessage(m.id)}>Ta bort</Button></Alert>)
                }
            </div>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        messages: state.messageAlerts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMessageAlerts: data => dispatch({ type: 'MESSAGE_ALERTS', data })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage);
