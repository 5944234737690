import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import authService from './api-authorization/AuthorizeService';
import { Title } from './Title';

export function EnvironmentalAdvisors({ advisors, setAdvisors, title, bigTitle }) {

    const [fetching, setFetching] = useState(false);

    const fetchAdvisors = useCallback(async () => {
        if (advisors && advisors.length === 0) {
            setFetching(true);
            const token = await authService.getAccessToken();
            const response = await fetch('api/Customer/GetAdvisors', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setAdvisors(data);
            setFetching(false);
        }
    }, [advisors, setAdvisors])

    useEffect(() => {
        fetchAdvisors()
    }, [fetchAdvisors])

    return (
        <>
            <Title bigTitle={bigTitle} title={title || "Miljörådgivare:"} />
            {fetching
                ? <BeatLoader size={15} margin={2} />
                : <div className="margin-top-bottom">
                    <ul>
                        {advisors && advisors.map((x,i) => <li key={i}>{x.name}<br />Epost: <a href={"mailto:" + x.email} className="href-link">{x.email}</a></li>)}
                    </ul>
                </div>
            }            
        </>
    );
}

const mapStateToProps = state => {
    return {
        advisors: state.advisors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAdvisors: (data) => dispatch({ type: 'ADVISORS', data })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentalAdvisors);


