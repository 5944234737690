import React, { Component } from 'react';

export class Unauthorized extends Component {
    static displayName = Unauthorized.name;

  render() {
    return (
      <div>
        <h1>Beh&ouml;righet saknas</h1>
      </div>
    );
  }
}
