import React, { createRef } from 'react';
import authService from './api-authorization/AuthorizeService';

export function AuthenticatedLink({ url, filename, children }) {
    const link = createRef()

    const handleAction = async (e) => {
        if (!link.current.href.endsWith('/#')) {
            return
        }
        e.preventDefault();
        const token = await authService.getAccessToken();
        const result = await fetch(url, {
            headers: { 'Authorization': `Bearer ${token}` }
        })

        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        link.current.download = filename
        link.current.href = href

        link.current.click()
    }

    return (
        <>
            <a className="href-link" role='button' ref={link} href="/#" onClick={handleAction}>{children}</a>
        </>
    )
}