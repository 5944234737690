import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            isManagerOrAdmin: false,
            isAdmin: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user, isManagerOrAdmin, isAdmin] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.isManagerOrAdmin(), authService.isAdmin()])
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            isManagerOrAdmin,
            isAdmin
        });
    }

    render() {
        const { isAuthenticated, userName, isManagerOrAdmin, isAdmin } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            const messagesPath = `${ApplicationPaths.messagesPath}`;
            const managerPath = `${ApplicationPaths.managerPath}`;
            const adminPath = `${ApplicationPaths.adminPath}`;
            return this.authenticatedView(userName, profilePath, logoutPath, messagesPath, managerPath, adminPath, isManagerOrAdmin, isAdmin);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, messagesPath, managerPath, adminPath, isManagerOrAdmin, isAdmin) {
        const radius = isManagerOrAdmin && !isAdmin ? 'right-radius' : '';
        return (<Fragment>
            {isManagerOrAdmin &&
                <NavItem active={this.props.location.pathname === messagesPath} className='admin-background left-radius'>
                    <NavLink tag={Link} className="text-black" to={messagesPath}>Meddelanden</NavLink>
                </NavItem>
            }
            {isManagerOrAdmin &&
                <NavItem active={this.props.location.pathname === managerPath} className={`admin-background ${radius}`}>
                <NavLink tag={Link} className='text-black' to={managerPath}>Kunder</NavLink>
                </NavItem>
            }
            {isAdmin &&
                <NavItem active={this.props.location.pathname === adminPath} className="admin-background right-radius">
                    <NavLink tag={Link} className="text-black" to={adminPath}>Admin</NavLink>
                </NavItem>
            }
            {/*
            <NavItem>
                <NavLink tag={Link} className="text-white" to={profilePath}>{userName}</NavLink>
            </NavItem>
            */}
            <NavItem>
                <NavLink tag={Link} className="text-white" to={logoutPath}>Logga ut</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*            <NavItem>
                <NavLink tag={Link} className="text-white" to={registerPath}>Register</NavLink>
            </NavItem>*/}
            <NavItem>
                <NavLink tag={Link} className="text-white" to={loginPath}>Logga in</NavLink>
            </NavItem>
            
        </Fragment>);
    }
}

export default withRouter(LoginMenu);