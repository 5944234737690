import React from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/19fa5f_154193d7f92f4af6b42b18d8799ff1de~mv2.webp';
import logo from '../assets/19fa5f_a7e660fc997b43d6a570782c81390eaf_mv2.webp';


export function StartPage() {

    return (
        <div className="margin-top-bottom">
            <h2>Välkommen till Lundstams kundportal!</h2>

            <div className='vertical-space' />

            <div className="margin-bottom">
                Här kan du:
                <ul>
                    <li>Se och beställa tömning av <Link to="/containrar" className="href-link">dina containrar</Link></li>
                    <li>Se dina <Link to="/karl" className="href-link">innehav av kärl</Link></li>
                    <li>Ta fram <Link to="/statistik" className="href-link">statistikrapporter</Link></li>
                    <li>Se <Link to="/dokument" className="href-link">dina avtal och andra dokument</Link></li>
                </ul>
            </div>

            <div className='vertical-space' />


            <div style={{ position: "relative", marginTop: "50px"}}>
                <img alt="Lundstams" src={image} width="100%" />
                <img alt="Lundstams" src={logo} style={{ width: "160px", position: "absolute", bottom: "10px", right: "10px" }} />
            </div>
        </div>
    );
}
