import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import queryString from 'query-string';

import { AuthenticatedLink } from './AuthenticatedLink';
import authService from './api-authorization/AuthorizeService';
import AdminFetchComponent from './AdminFetchComponent';
import GroupCustomerFetchComponent from './GroupCustomerFetchComponent';

function GarbageCansPage({ role, customerNo, setCustomerNo, garbageCansCustomerNo, setGarbageCansCustomerNo,  garbageCans, setGarbageCans }) {

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [fetchError, setFetchError] = useState('');

    const fetchGarbageCans = useCallback(async () => {
        if (authService.isManagerOrAdminOrGroupCustomerRole(role) && customerNo === '') {
            return;
        }
        setFetching(true);
        const token = await authService.getAccessToken();

        const managerCustomerNumber = authService.isManagerOrAdminOrGroupCustomerRole(role) ? `/${customerNo}` : '';
        const groupCustomer = authService.isGroupCustomerRole(role) ? 'GroupCustomer' : '';
        const url = `api/Customer/GarbageCans${groupCustomer}${managerCustomerNumber}`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                throw new Error('Anrop misslyckades');
            }
            const data = await response.json();
            setGarbageCans(data);
        } catch (error) {
            setFetchError(error.message);
            console.log("Anrop fel " + error.message);
        }
        setFetching(false);
        setGarbageCansCustomerNo(customerNo);
        setFetched(true);
    }, [role, customerNo, setGarbageCans, setGarbageCansCustomerNo])

    useEffect(() => {
        const customerNeedsFetch = (garbageCans === null && authService.isCustomerRole(role));
        const adminNeedsFetch = (authService.isManagerOrAdminOrGroupCustomerRole(role) && customerNo !== null && customerNo !== garbageCansCustomerNo);
        if (customerNeedsFetch || adminNeedsFetch) {
            fetchGarbageCans();
        }
    }, [fetchGarbageCans, customerNo, garbageCansCustomerNo, role, garbageCans])

    

    const noGarbageCansFound = !fetching
        && garbageCans !== null
        && garbageCans.length === 0
        && fetched;

    const reportQuery = { ssrsReportName: 'Kärl med schema' };
    const managerCustomerNumber = authService.isManagerOrAdminRole(role) ? `/${customerNo}` : '';
    const url = `/api/Customer/CreateReport${managerCustomerNumber}?${queryString.stringify(reportQuery)}`;

    return (
      <div>
            <h1>Kärl</h1>

            <div className="margin-top-bottom">
                Kontakta <Link to="/kontakt" className="href-link">ditt lokala kontor</Link> för beställning.
            </div>
            <div className="margin-top-bottom">
                Förklaring till kolumnen service:
                <ul>
                    <li><strong>(14)</strong> Siffra inom parentes anger antal dagar mellan varje tömning</li>
                    <li><strong>Udda/Jämn</strong> anger att tömning sker udda respektive jämn vecka</li>
                    <li><strong>Budas</strong> betyder att du får ringa in när du vill ha tömning</li>
                </ul>
            </div>

            <div>
                {authService.isManagerOrAdminRole(role)
                    && <AdminFetchComponent
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo} />
                }

                {authService.isGroupCustomerRole(role)
                    && <GroupCustomerFetchComponent
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo} />
                }

                {fetching
                    ? <div className='margin-top-bottom'>Hämtar kärl...<BeatLoader size={15} margin={2} /></div>
                    : <div className='margin-top-bottom'>
                        {garbageCans !== null && garbageCans.length > 0 &&
                            <>
                                <div className='margin-bottom'><h4>{garbageCans[0].customerName}</h4></div>

                                <div className='margin-top-bottom'>
                                    <ul>
                                        <li><AuthenticatedLink url={url} filename='Lundstams Kärl med schema.xlsx'>Spara som Excel-fil</AuthenticatedLink></li>
                                    </ul>
                                </div>
                                <Table size="sm">
                                    <thead>
                                        <tr>
                                            <th>Littera/arbetsplats</th>
                                            <th>Avsändare</th>
                                            <th>Hämtas adress</th>
                                            <th>Kärltyp</th>
                                            <th>Fraktion</th>
                                            <th>Service</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {garbageCans.map((r, i) => <GarbageCanComponent key={i} role={role} rentalObject={r} />)}
                                    </tbody>
                                </Table>
                            </>
                        }
                    </div>
                }
                {noGarbageCansFound && !fetchError && <div className='margin-top-bottom'>Inga kärl hittades.</div>}
                {fetchError && <div className='margin-top-bottom'>{fetchError}</div>}
            </div>
      </div>
    );
}

function GarbageCanComponent({ rentalObject: r }) {

    const missingData = '';

    function checkEmpty(str, replacement) {
        if (!str || /^\s*$/.test(str)) {
            return replacement
        }
        return str;
    }

    return (
        <tr>
            <td>{r.litteraKod}</td>
            <td>{checkEmpty(r.avsandare, missingData)}</td>
            <td>{checkEmpty(r.hamtningsadress, missingData)}</td>
            <td>{checkEmpty(r.karltyp, missingData)}</td>
            <td>{checkEmpty(r.fraktion, missingData)}</td>
            <td>{checkEmpty(r.service, 'Budas')}</td>
        </tr>
    )
}

const mapStateToProps = state => {
    return {
        garbageCans: state.garbageCans,
        customerNo: state.customerNo,
        garbageCansCustomerNo: state.garbageCansCustomerNo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGarbageCans: (data) => dispatch({ type: 'GARBAGE_CANS', data }),
        setCustomerNo: (data) => dispatch({ type: 'CUSTOMER_NO', data }),
        setGarbageCansCustomerNo: (data) => dispatch({ type: 'GARBAGE_CANS_CUSTOMER_NO', data }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GarbageCansPage);
