import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import LoginMenu from './api-authorization/LoginMenu';
import './NavMenu.css';
import logo from '../assets/19fa5f_a7e660fc997b43d6a570782c81390eaf_mv2.webp';


class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom blue-nav" light>
          <Container>
            <NavbarBrand tag={Link} to="/">
                <img alt="Lundstams" src={logo} style={{ width: "160px", marginTop: "-7px", marginRight: "10px" }} />
                <span style={{ display: 'inline-block', color: "white" }} >Kundportal</span>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem active={this.props.location.pathname === "/containrar"}>
                  <NavLink tag={Link} className="text-white" to="/containrar">Containrar</NavLink>
                </NavItem>
                <NavItem active={this.props.location.pathname === "/karl"}>
                  <NavLink tag={Link} className="text-white" to="/karl">Kärl</NavLink>
                </NavItem>
                <NavItem active={this.props.location.pathname === "/statistik"}>
                  <NavLink tag={Link} className="text-white" to="/statistik">Statistik</NavLink>
                </NavItem>
                <NavItem active={this.props.location.pathname === "/dokument"}>
                  <NavLink tag={Link} className="text-white" to="/dokument">Avtal och dokument</NavLink>
                </NavItem>
                <NavItem active={this.props.location.pathname === "/farligtavfall"}>
                  <NavLink tag={Link} className="text-white" to="/farligtavfall">Farligt avfall</NavLink>
                </NavItem>
                <NavItem active={this.props.location.pathname === "/kontakt"}>
                  <NavLink tag={Link} className="text-white" to="/kontakt">Kontakt</NavLink>
                </NavItem>
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default withRouter(NavMenu);