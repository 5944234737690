import React from 'react';
import { Title } from './Title';

export function SupportContacts({ bigTitle, title, noHede }) {

    return (
        <div className="margin-top-bottom">
            <div className="margin-top-bottom">
                <h4>{title}</h4>
            </div>
            <Title bigTitle={true} title="Östersund" />
            <div className="margin-top-bottom">
                <div className="margin-top-bottom">
                <ul>
                    <li>
                        Epost: <a href="mailto:info.osd@lundstams.se" className="href-link">info.osd@lundstams.se</a>
                        <br />
                            Telefon: 063-576060
                        </li>
                </ul>
                </div>
            </div>
            <Title bigTitle={bigTitle} title="Åre" />
            <div className="margin-top-bottom">
                <div className="margin-top-bottom">
                    <ul>
                        <li>
                            Epost: <a href="mailto:info.are@lundstams.se" className="href-link">info.are@lundstams.se</a>
                            <br />
                            Telefon: 0647-31042
                        </li>
                    </ul>
                </div>
            </div>
            <Title bigTitle={bigTitle} title="Umeå" />
            <div className="margin-top-bottom">
                <div className="margin-top-bottom">
                    <ul>
                        <li>
                            Epost: <a href="mailto:info.umea@lundstams.se" className="href-link">info.umea@lundstams.se</a>
                            <br />
                            Telefon: 090-133500
                        </li>
                    </ul>
                </div>
            </div>
            {!noHede &&
                <div>
                <Title bigTitle={bigTitle} title="Hede" />
                    <div className="margin-top-bottom">
                        <div className="margin-top-bottom">
                            <ul>
                                <li>
                                    Epost: <a href="mailto:info.hede@lundstams.se" className="href-link">info.hede@lundstams.se</a>
                                    <br />
                                    Telefon: 0684-10277
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

