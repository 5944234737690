import { createStore } from 'redux';

const initialState = {
    messageAlerts: [],
    rentalObjects: null,
    rentalObjectsCustomerNo: null,
    garbageCans: null,
    garbageCansCustomerNo: null,
    documents: null,
    documentsCustomerNo: null,
    customerNo: '',
    advisors: [],
    groupCustomerNumbers: null,
    adminFetchCustomerNo: ''
};

const rootReducer = function (state = initialState, action) {
//    console.log("Action " + action.type + " " + action.data?.length)
    switch (action.type) {
        case 'MESSAGE_ALERTS':
            return { ...state, messageAlerts: action.data };
        case 'RENTAL_OBJECTS':
            return { ...state, rentalObjects: action.data };
        case 'GARBAGE_CANS':
            return { ...state, garbageCans: action.data };
        case 'DOCUMENTS':
            return { ...state, documents: action.data };
        case 'RENTAL_OBJECTS_CUSTOMER_NO':
            return { ...state, rentalObjectsCustomerNo: action.data };
        case 'GARBAGE_CANS_CUSTOMER_NO':
            return { ...state, garbageCansCustomerNo: action.data };
        case 'DOCUMENTS_CUSTOMER_NO':
            return { ...state, documentsCustomerNo: action.data };
        case 'CUSTOMER_NO':
            return { ...state, customerNo: action.data };
        case 'ADVISORS':
            return { ...state, advisors: action.data };
        case 'GROUP_CUSTOMER_NUMBERS':
            return { ...state, groupCustomerNumbers: action.data }
        case 'ADMIN_FETCH_CUSTOMER_NO':
            return { ...state, adminFetchCustomerNo: action.data }
        default:
            return state;
    }
}

const store = createStore(rootReducer);

export { store };