import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

function AdminFetchComponent({ setCustomerNo, adminFetchCustomerNo, setAdminFetchCustomerNo }) {

    const handleFetch = e => {
        e.preventDefault();
        const customerNo = e.target.customerNumber.value;
        setCustomerNo(customerNo);
    }

    return (
        <Form onSubmit={handleFetch}>
            <FormGroup>
                <Label for="customerNumber">Kundnummer:</Label>
                <Input
                    required
                    type="number"
                    min={1}
                    name="customerNumber"
                    value={adminFetchCustomerNo}
                    onChange={e => setAdminFetchCustomerNo(e.target.value)}
                    id="customerNumber"
                    placeholder="Ange kundnummer" />
            </FormGroup>
            <Button>Hämta</Button>
        </Form>
    );
}

const mapStateToProps = state => {
    return {
        adminFetchCustomerNo: state.adminFetchCustomerNo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAdminFetchCustomerNo: (data) => dispatch({ type: 'ADMIN_FETCH_CUSTOMER_NO', data }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFetchComponent);

