import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { Unauthorized } from './Unauthorized';

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated, isManagerOrAdmin, isAdmin, role } = this.state;
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, adminRole, managerOrAdminRole, ...rest } = this.props;
            const okAdminComponent = isAdmin && adminRole;
            const okManagerComponent = isManagerOrAdmin && managerOrAdminRole;
            const okCustomerComponent = authenticated && !managerOrAdminRole && !adminRole;
            return <Route {...rest}
                render={(props) => {
                    if (okAdminComponent || okManagerComponent || okCustomerComponent) {
                        return <Component {...props} role={role} />
                    } else if (managerOrAdminRole || adminRole) {
                        return <Unauthorized />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const role = await authService.getRole();
        const isManagerOrAdmin = authService.isManagerOrAdminRole(role);
        const isAdmin = authService.isAdminRole(role);
        this.setState({ ready: true, authenticated, isManagerOrAdmin, isAdmin, role });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false, isManagerOrAdmin: false, isAdmin: false });
        await this.populateAuthenticationState();
    }
}
