import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';
import { BeatLoader } from 'react-spinners'; 

import authService from './api-authorization/AuthorizeService';

function GroupCustomerFetchComponent({ customerNo, setCustomerNo, groupCustomerNumbers, setGroupCustomerNumbers }) {

    const [loadingGroupCustomerNumbers, setLoadingGroupCustomerNumbers] = useState(false);

    const fetchGroupCustomerNumbers = useCallback(async () => {
        if (groupCustomerNumbers !== null) {
            return;
        }
        setLoadingGroupCustomerNumbers(true);
        const token = await authService.getAccessToken();
        const response = await fetch('api/Customer/GetGroupCustomerNumbers', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setGroupCustomerNumbers(data);
        if (data.length > 0) {
            const customerNo = data[0].customerNumber;
            setCustomerNo(customerNo);
        }
        setLoadingGroupCustomerNumbers(false);
    }, [setCustomerNo, setLoadingGroupCustomerNumbers, setGroupCustomerNumbers])

    useEffect(() => {
        fetchGroupCustomerNumbers()
    }, [fetchGroupCustomerNumbers])

    const handleCustomerChange = customerNo => {
        setCustomerNo(customerNo);
    }

    return (
        <div>
            {
                loadingGroupCustomerNumbers &&
                <BeatLoader size={15} margin={2} />
            }
            {
                !loadingGroupCustomerNumbers && groupCustomerNumbers !== null && groupCustomerNumbers.length > 0 &&
                <FormGroup>
                    <Label for="customerNumber">Kund:</Label>
                    <Input
                        type="select"
                        name="select"
                        value={customerNo}
                        onChange={e => handleCustomerChange(e.target.value)}
                        id="customerNumber">
                        {
                            groupCustomerNumbers.map(g => <option value={g.customerNumber}>{g.name} - {g.customerNumber}</option>)
                        }
                    </Input>
                </FormGroup>
            }
        </div>
        
    );
}

const mapStateToProps = state => {
    return {
        groupCustomerNumbers: state.groupCustomerNumbers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGroupCustomerNumbers: (data) => dispatch({ type: 'GROUP_CUSTOMER_NUMBERS', data }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupCustomerFetchComponent);
