import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import { BeatLoader } from 'react-spinners';
import queryString from 'query-string';
import { saveAs } from 'file-saver';

import authService from './api-authorization/AuthorizeService';

import "react-datepicker/dist/react-datepicker.css";
import GroupCustomerFetchComponent from './GroupCustomerFetchComponent';


function ReportsPage({ role, customerNo, setCustomerNo }) {

    const lastDayOfPreviousMonth = new Date();
    lastDayOfPreviousMonth.setMonth(lastDayOfPreviousMonth.getMonth(), 0);
    const firstDayOfPreviousMonth = new Date();
    firstDayOfPreviousMonth.setDate(1);
    firstDayOfPreviousMonth.setMonth(firstDayOfPreviousMonth.getMonth() - 1);

    const [reports, setReports] = useState([]);
    const [selectedSsrsReportName, setSelectedSsrsReportName] = useState('');
    const [selectedReport, setSelectedReport] = useState({});
    const [fromDate, setFromDate] = useState(firstDayOfPreviousMonth);
    const [toDate, setToDate] = useState(lastDayOfPreviousMonth);
    const [fetching, setFetching] = useState(false);
    const [fetchError, setFetchError] = useState('');

    //const link = createRef();

    const fetchReports = useCallback(async () => {
        setFetching(true);
        const token = await authService.getAccessToken();
        const response = await fetch('api/Customer/GetReportTypes', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        //console.log(JSON.stringify(data));
        setReports(data);
        setCurrentReport(data, data[0].reportNameSSRS);
        setFetching(false);
    }, [])

    const setCurrentReport = (reports, ssrsReportName) => {
        setSelectedSsrsReportName(ssrsReportName);
        setSelectedReport(reports.find(r => r.reportNameSSRS === ssrsReportName));
    }

    useEffect(() => {
        fetchReports()
    }, [fetchReports])

    const handleClick = async e => {
        e.preventDefault();
        setFetchError('');

        const token = await authService.getAccessToken();
        const reportQuery = { ssrsReportName: selectedSsrsReportName };
        if (selectedReport.useDates) {
            reportQuery.fromDate = fromDate.toISOString().slice(0, 10);
            reportQuery.toDate = toDate.toISOString().slice(0, 10);
        }
        const managerCustomerNumber = authService.isManagerOrAdminOrGroupCustomerRole(role) ? `/${customerNo}` : '';
        const groupCustomer = authService.isGroupCustomerRole(role) ? 'GroupCustomer' : '';
        const url = `api/Customer/CreateReport${groupCustomer}${managerCustomerNumber}?${queryString.stringify(reportQuery)}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades.`);
            }
            const data = await response.blob();

            const filename = decodeURIComponent(response.headers.get('Content-Disposition').split("filename*=UTF-8''")[1]);
            saveAs(data, filename);
        } catch (error) {
            setFetchError(error.message);
        }
    }

    return (
        <div>
            <h1>Statistik</h1>
            {reports && reports.length > 0 &&
                <div className='margin-bottom'><h4>{reports[0].customerName}</h4></div>
            }

            <Form onSubmit={handleClick}>
                {authService.isManagerOrAdminRole(role)
                    && <AdminFetchComponent
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo} />}


                {authService.isGroupCustomerRole(role)
                    && <GroupCustomerFetchComponent
                        customerNo={customerNo}
                        setCustomerNo={setCustomerNo} />
                }

                {fetching
                    ? <BeatLoader size={15} margin={2} />
                    : <FormGroup>
                        <Label for="report">Rapport:</Label>
                        <Input
                            type="select"
                            name="select"
                            value={selectedSsrsReportName}
                            onChange={e => setCurrentReport(reports, e.target.value)}
                            id="report">
                            {reports.map(r => <option key={r.reportNameSSRS} value={r.reportNameSSRS}>{r.displayName}</option>)}
                        </Input>
                    </FormGroup>
                }
                {selectedReport && selectedReport.useDates &&
                    <>
                        <FormGroup>
                            <Label for="fromDate">Startdatum:</Label>
                            <DatePicker
                                className='form-control'
                                id="fromDate"
                                selected={fromDate}
                                onChange={date => setFromDate(date)}
                                dateFormat='yyyy-MM-dd'
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="toDate">Slutdatum:</Label>
                            <DatePicker
                                className='form-control'
                                id="toDate"
                                selected={toDate}
                                minDate={fromDate}
                                onChange={date => setToDate(date)}
                                dateFormat='yyyy-MM-dd'
                            />
                        </FormGroup>
                    </>
                }
                <Button>Hämta</Button>
                {/*                <a ref={link} href='/#' style={{display: 'none'}}>Hämta</a> */}
                {fetchError && <div className='margin-top-bottom'>{fetchError}</div>}
            </Form>
        </div>
    );
}

function AdminFetchComponent({ customerNo, setCustomerNo }) {

    return (
        <FormGroup>
            <Label for="customerNumber">Kundnummer:</Label>
            <Input
                required
                type="number"
                min={1}
                name="customerNumber"
                value={customerNo}
                onChange={e => setCustomerNo(e.target.value)}
                id="customerNumber"
                placeholder="Ange kundnummer" />
        </FormGroup>
    )
}

const mapStateToProps = state => {
    return {
        customerNo: state.customerNo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCustomerNo: (data) => dispatch({ type: 'CUSTOMER_NO', data }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
