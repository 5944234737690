import React, { Component } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import ContainersPage from './components/ContainersPage';
import GarbageCansPage from './components/GarbageCansPage';
import ReportsPage from './components/ReportsPage';
import DocumentsPage from './components/DocumentsPage';
import HazardousWastePage from './components/HazardousWastePage';
import ContactPage from './components/ContactPage';
import MessagesPage from './components/MessagesPage';
import { ManagerPage } from './components/ManagerPage';
import { AdminPage } from './components/AdminPage';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';

import './custom.css';

registerLocale('sv', sv)
setDefaultLocale('sv');

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <Layout>
              <AuthorizeRoute exact path='/' component={Home} />
              <AuthorizeRoute path='/containrar' component={ContainersPage} />
              <AuthorizeRoute path='/karl' component={GarbageCansPage} />
              <AuthorizeRoute path='/statistik' component={ReportsPage} />
              <AuthorizeRoute path='/dokument' component={DocumentsPage} />
              <AuthorizeRoute path='/farligtavfall' component={HazardousWastePage} />
              <AuthorizeRoute path='/kontakt' component={ContactPage} />
              <AuthorizeRoute managerOrAdminRole path='/meddelanden' component={MessagesPage} />
              <AuthorizeRoute managerOrAdminRole path='/kunder' component={ManagerPage} />
              <AuthorizeRoute adminRole path='/admin' component={AdminPage} />
              <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          </Layout>
      );
  }
}
