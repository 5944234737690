import React from 'react';
import EnvironmentalAdvisors from './EnvironmentalAdvisors';
import { SupportContacts } from './SupportContacts';

function ContactPage() {

    return (
        <div>
            <h1>Kontakta Lundstams</h1>

            <div className="margin-top-bottom">
                <SupportContacts bigTitle />
            </div>


            <div className="margin-top-bottom">
                <EnvironmentalAdvisors bigTitle />
            </div>


        </div>
    );
}

export default ContactPage;
