import React from 'react';

export function Title({ title, bigTitle }) {
    if (bigTitle) {
        return <h5>{title}</h5>
    }
    return <div className="margin-top-bottom">
        {title}
    </div>
}



