import React from 'react';
import { StartPage } from './StartPage';

function Home() {


    return <StartPage />
}


export { Home };