import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { MessageAlerts } from './MessageAlerts';
import NavMenu from './NavMenu';

function Layout({ messages, setMessageAlerts, children }) {


    const fetchMessages = useCallback(async () => {
        const response = await fetch('api/Customer/GetMessages');
        const data = await response.json();
        setMessageAlerts(data);
    }, [setMessageAlerts])

    useEffect(() => {
        fetchMessages()
    }, [fetchMessages])

    return (
      <div>
        <NavMenu />
        <MessageAlerts messages={messages} />
        <Container>
          {children}
        </Container>
      </div>
    );
  
}

const mapStateToProps = state => {
    return {
        messages: state.messageAlerts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMessageAlerts: data => dispatch({ type: 'MESSAGE_ALERTS', data })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
