import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, FormGroup, Label, Input, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import queryString from 'query-string';

import authService from './api-authorization/AuthorizeService';


function ManagerPage(props) {

    const [customersToAdd, setCustomersToAdd] = useState([]);
    const [existingCustomers, setExistingCustomers] = useState([]);
    const [loadingCustomers, setLoadingCustomers] = useState(false);
    const [saveCustomerError, setSaveCustomerError] = useState(null);
    const [savingCustomer, setSavingCustomer] = useState(false);
    const [changeEmailError, setChangeEmailError] = useState(null);

    const fetchCustomers = useCallback(async () => {
        setLoadingCustomers(true);
        const token = await authService.getAccessToken();
        const response = await fetch('api/Manager/GetCustomers', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setCustomersToAdd(data.filter(x => x.email === null));
        setExistingCustomers(data.filter(x => x.email != null));
        setLoadingCustomers(false);
    }, [])

    useEffect(() => {
        fetchCustomers()
    }, [fetchCustomers])

    const handleSubmit = async (e, email, customerNumber, name) => {
        e.preventDefault();
        setSavingCustomer(true);
        const token = await authService.getAccessToken();
        if (email === '') {
            email = null;
        }
        const newUser = { email, customerNumber, name };
        try {
            const response = await fetch('api/Manager/SaveCustomer', {
                method: 'POST',
                body: JSON.stringify(newUser),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();

            if (!data.success) {
                throw new Error(data.result);
            }
            setSaveCustomerError(null);
            setSavingCustomer(false);
            fetchCustomers();
        } catch (error) {
            setSaveCustomerError(error.message);
            setSavingCustomer(false);
        }
    }

    const deleteUser = async email => {
        const token = await authService.getAccessToken();
        const reportQuery = { email };
        const url = `api/Manager/DeleteUser?${queryString.stringify(reportQuery)}`;

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
                throw new Error(data.result);
            }

            fetchCustomers();
            console.log(`User deleted ok ${email}`)
        } catch (error) {

            console.log(`User delete failed ${error.message}`)
        }
    }

    const changeEmail = async (e, email, newEmail) => {
        e.preventDefault();
        setSavingCustomer(true);

        const token = await authService.getAccessToken();
        const reportQuery = { email, newEmail };
        const url = `api/Manager/ChangeEmail?${queryString.stringify(reportQuery)}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
                throw new Error(data.result);
            }

            fetchCustomers();
            console.log(`User changed email ok ${newEmail}`);
            setSavingCustomer(false);
            setChangeEmailError(null);

        } catch (error) {
            setSavingCustomer(false);
            setChangeEmailError(error.message);
            console.log(`User change email failed ${error.message}`)
        }
    }

    return (
        <div>
            <h1>Hantera kunder</h1>

            <div>
                {loadingCustomers
                    ? <BeatLoader size={15} margin={2} />
                    :
                    <div>
                        <h4>Kunder som saknar epost</h4>
                        {customersToAdd.map(c =>
                            <CustomerComponent
                                key={c.customerNumber}
                                customer={c}
                                handleSubmit={handleSubmit}
                                saveCustomerError={saveCustomerError}
                                savingCustomer={savingCustomer} />)}
                        <div className='vertical-space' />
                        <h4>Befintliga kunder</h4>
                        {existingCustomers.map(c =>
                            <CustomerComponent
                                key={c.customerNumber}
                                customer={c}
                                handleSubmit={handleSubmit}
                                deleteUser={deleteUser}
                                changeEmail={changeEmail}
                                savingCustomer={savingCustomer}
                                changeEmailError={changeEmailError} />)}
                    </div>
                }
            </div>

        </div>
    );
}

function CustomerComponent({ customer: c, handleSubmit, saveCustomerError, savingCustomer, deleteUser, changeEmail, changeEmailError }) {

    const [showDetails, setShowDetails] = useState(false);
    const toggle = () => setShowDetails(!showDetails);
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    const toggleChangeEmail = () => setShowChangeEmail(!showChangeEmail);


    const hasEmail = () => {
        return c.email;
    }

    return (
        <div className='customer'>
            <Row>
                <Col xs={1}>Kundnr:</Col>
                <Col>{c.customerNumber}</Col>
                <Col>
                    <Button className='float-right' disabled={showDetails} onClick={() => setShowDetails(true)}>{hasEmail() ? 'Visa detaljer' : 'Lägg till'}</Button>
                </Col>

            </Row>
            <Row>
                <Col xs={1}>Namn:</Col>
                <Col>{c.name} ({c.organizationNumber})</Col>
            </Row>
            {
                c.email &&
                <Row>
                    <Col xs={1}>Epost:</Col>
                    <Col>{c.email}</Col>
                    {deleteUser &&
                        <Col>
                        <Button disabled={c.groupCustomerOnly} className="float-right" onClick={() => deleteUser(c.email)}>Ta bort</Button>
                        <Button disabled={c.groupCustomerOnly} className="float-right" style={{ marginRight: "10px" }} onClick={() => setShowChangeEmail(true)}>Ändra epost</Button>
                        </Col>
                    }
                </Row>
            }
            {showDetails &&
                <CustomerDetailsComponent
                    customer={c}
                    handleSubmit={handleSubmit}
                    saveCustomerError={saveCustomerError}
                    savingCustomer={savingCustomer}
                    isOpen={showDetails}
                    toggle={toggle} />
            }
            {showChangeEmail &&
                <CustomerChangeEmailComponent
                    customer={c}
                    handleSubmit={changeEmail}
                    changeEmailError={changeEmailError}
                    savingCustomer={savingCustomer}
                    isOpen={showChangeEmail}
                    toggle={toggleChangeEmail} />
            }
        </div>
    )
}

function CustomerDetailsComponent({ customer: c, handleSubmit, isOpen, toggle, saveCustomerError, savingCustomer}) {

    const [fetchingDetails, setFetchingDetails] = useState(false);
    const [details, setDetails] = useState(null);
    const [email, setEmail] = useState('');

    const customerNumber = c.customerNumber;

    const fetchCustomerDetails = useCallback(async () => {

        setFetchingDetails(true);
        const token = await authService.getAccessToken();
        try {
            const response = await fetch(`api/Manager/GetCustomerDetails?customerNumber=${customerNumber}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();

            setDetails(data);
            setFetchingDetails(false);
        } catch (error) {
            setFetchingDetails(false);

        }
    }, [customerNumber]);

    useEffect(() => {
        fetchCustomerDetails()
    }, [fetchCustomerDetails])

    const emailInputField = c.status === 'Epost saknas';

    return (
        <Modal size='lg' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{c.name}</ModalHeader>

            <ModalBody>
                {fetchingDetails
                    ? < BeatLoader size={15} margin={2} />
                    : null
                }
                {details &&
                    <div>
                        <CustomerDetailsWarning messages={details.warningMessages} />
                    </div>
                }
                <div className='horisontal-line' />

                {details &&
                    emailInputField &&
                    <Form onSubmit={e => handleSubmit(e, email, c.customerNumber, c.name)}>
                        <FormGroup>
                            <Label for="addEmail">Epost:</Label>
                            <Input
                                required
                                type="email"
                                name="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                id="addEmail"
                                placeholder="Ange epost-adress" />
                        </FormGroup>
                        <Button disabled={savingCustomer}>{emailInputField ? 'Skicka inbjudan' : 'Spara'}</Button>
                    </Form>
                }
                {saveCustomerError &&
                    <div className="margin-top-bottom">{saveCustomerError}</div>
                }
            </ModalBody>
        </Modal>
    )
}

function CustomerDetailsWarning({ messages }) {

    const getColor = type => {
        if (type === null) {
            return "info";
        }
        if (type === "Error") {
            return "danger";
        }
        return type.toLowerCase();
    }

    const getBookingNumber = m => {
        return m.rentalObjectBookingNr ? `Avtalsnr ${m.rentalObjectBookingNr}` : null;
    }

    const getMessageAsBullets = msg => {
        const list = msg.split('\n');
        return list.map((l, i) => <li key={i}>{l}</li>)
    }

    return (
        <div>
            <h4>Varningar:</h4>
            {
                messages && messages.length > 0
                    ? messages.map((m, i) => <Alert key={i} color={getColor(m.type)}><div>{getBookingNumber(m)}<ul>{getMessageAsBullets(m.message)}</ul></div></Alert>)
                    : <div>Inga varningar hittades</div>

            }
        </div>
    )
}

function CustomerChangeEmailComponent({ customer: c, handleSubmit, isOpen, toggle, changeEmailError, savingCustomer }) {

    const [newEmail, setNewEmail] = useState('');

    return (
        <Modal size='lg' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{c.name}</ModalHeader>

            <ModalBody>

                <Form onSubmit={e => handleSubmit(e, c.email, newEmail)}>
                    <FormGroup>
                        <Label for="addEmail">Nuvarande epost:</Label>
                        <Input
                            required
                            type="email"
                            name="email"
                            value={c.email}
                            id="addEmail"
                            placeholder="Nuvarande epost-adress" />
                        <Label for="newEmail">Ange ny epost:</Label>
                        <Input
                            required
                            type="email"
                            name="newEmail"
                            value={newEmail}
                            onChange={e => setNewEmail(e.target.value)}
                            id="newEmail"
                            placeholder="Ange ny epost-adress" />
                    </FormGroup>
                    <Button disabled={savingCustomer}>Spara</Button>
                </Form>
                {changeEmailError &&
                    <div className="margin-top-bottom">{changeEmailError}</div>
                }
            </ModalBody>
        </Modal>
    )
}


export { ManagerPage };