import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import queryString from 'query-string';

import { AuthenticatedLink } from './AuthenticatedLink';
import authService from './api-authorization/AuthorizeService';
import AdminFetchComponent from './AdminFetchComponent';
import GroupCustomerFetchComponent from './GroupCustomerFetchComponent';

function DocumentsPage({ role, customerNo, setCustomerNo, documentsCustomerNo, setDocumentsCustomerNo, documents, setDocuments }) {

    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [fetchError, setFetchError] = useState('');

    const fetchDocuments = useCallback(async () => {
        if (authService.isManagerOrAdminOrGroupCustomerRole(role) && customerNo === '') {
            return;
        }
        setFetching(true);
        const token = await authService.getAccessToken();

        const managerCustomerNumber = authService.isManagerOrAdminOrGroupCustomerRole(role) ? `/${customerNo}` : '';
        const groupCustomer = authService.isGroupCustomerRole(role) ? 'GroupCustomer' : '';
        const url = `api/Customer/Documents${groupCustomer}${managerCustomerNumber}`;

        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                throw new Error('Anrop misslyckades');
            }
            const data = await response.json();

            setDocuments(data);
        } catch (error) {
            setFetchError(error.message);
        }
        setFetching(false);
        setDocumentsCustomerNo(customerNo);
        setFetched(true);
    }, [role, customerNo, setDocuments, setDocumentsCustomerNo])

    useEffect(() => {
        const customerNeedsFetch = (documents === null && authService.isCustomerRole(role));
        const adminNeedsFetch = (authService.isManagerOrAdminOrGroupCustomerRole(role) && customerNo !== null && customerNo !== documentsCustomerNo);
        if (customerNeedsFetch || adminNeedsFetch) {
            fetchDocuments();
        }
    }, [fetchDocuments, customerNo, documentsCustomerNo, role, documents])

    const noDocumentsFound = !fetching
        && documents !== null
        && documents.length === 0
        && fetched;

    return (
        <div className="margin-bottom-large">
            <h1>Avtal och dokument</h1>
            <div className="margin-top-bottom">
                Miljötillstånd hittar du på <a href="https://www.lundstams.se/tillstand-certifikat" target="_blank" rel="noopener noreferrer" className="href-link">Lundstams hemsida</a>.
            </div>

            {authService.isManagerOrAdminRole(role)
                && <AdminFetchComponent
                    customerNo={customerNo}
                    setCustomerNo={setCustomerNo} />
            }

            {authService.isGroupCustomerRole(role)
                && <GroupCustomerFetchComponent
                    customerNo={customerNo}
                    setCustomerNo={setCustomerNo} />
            }

            {fetching
                ? <div className='margin-top-bottom'>Hämtar avtal och dokument...<BeatLoader size={15} margin={2} /></div>
                : <DocumentList role={role} documents={documents} customerNo={customerNo} />
            }
            {noDocumentsFound && !fetchError && <div className='margin-top-bottom'>Inga avtal eller dokument hittades.</div>}
            {fetchError && <div className='margin-top-bottom'>{fetchError}</div>}

        </div>
    );
}

function DocumentList({ role, documents, customerNo }) {
    const contracts = documents === null ? [] : documents.filter(x => x.type === 'Avtal');
    const environmentDeclarations = documents === null ? [] : documents.filter(x => x.type === 'Miljodeklaration');
    const sortilogs = documents === null ? [] : documents.filter(x => x.type === 'Sortilog');
    const controlReports = documents === null ? [] : documents.filter(x => x.type === 'KontrollRapport');
    return (
        <div className='margin-top-bottom'>
            {documents !== null && documents.length > 0 &&
                <div className='margin-bottom'><h4>{documents[0].customerName}</h4></div>
            }
            {contracts.length > 0 &&
                <>
                    <h4>Avtal</h4>
                    <ul>
                        {contracts.map(d => <DocumentComponent role={role} key={d.name} document={d} customerNo={customerNo} />)}
                    </ul>
                </>
            }
            {sortilogs.length > 0 &&
                <>
                    <h4>Sortiloger</h4>
                    <ul>
                        {sortilogs.map(d => <DocumentComponent role={role} key={d.name} document={d} useUrl />)}
                    </ul>
                </>
            }
            {controlReports.length > 0 &&
                <>
                    <h4>Besiktningsdokument</h4>
                    <ul>
                        {controlReports.map(d => <DocumentComponent role={role} key={d.name} document={d} customerNo={customerNo} />)}
                    </ul>
                </>
            }
            {environmentDeclarations.length > 0 &&
                <>
                    <h4>Transportdokument (farligt avfall och farligt gods)</h4>
                    <ul>
                    {environmentDeclarations.map(d => <DocumentComponent role={role} key={d.name} document={d} customerNo={customerNo} />)}
                    </ul>
                </>
            }

        </div >
        )
}

function DocumentComponent({ role, document: d, customerNo, useUrl }) {

    const manager = authService.isManagerOrAdminOrGroupCustomerRole(role) ? `Manager` : '';
    const groupCustomer = authService.isGroupCustomerRole(role) ? `GroupCustomer` : '';
    const queryStr = authService.isGroupCustomerRole(role) ? queryString.stringify({ customerNumber: customerNo }) : '';

    const url = `/api/Customer/Document${manager}${groupCustomer}/${d.documentId}${queryStr}`;

    return (
        <li>
            { useUrl
                ? <a href={d.url} target="_blank" rel="noopener noreferrer" className="href-link">{d.name}</a>
                : <AuthenticatedLink url={url} filename={d.name}>{d.name}</AuthenticatedLink>
                }            
        </li>
    )
}

const mapStateToProps = state => {
    return {
        documents: state.documents,
        customerNo: state.customerNo,
        documentsCustomerNo: state.documentsCustomerNo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDocuments: (data) => dispatch({ type: 'DOCUMENTS', data }),
        setCustomerNo: (data) => dispatch({ type: 'CUSTOMER_NO', data }),
        setDocumentsCustomerNo: (data) => dispatch({ type: 'DOCUMENTS_CUSTOMER_NO', data }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
