import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { BeatLoader } from 'react-spinners'; 
import queryString from 'query-string';

import authService from './api-authorization/AuthorizeService';

function AdminPage(props) {

    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserRole, setNewUserRole] = useState('Manager');
    const [newCustomerNumbers, setNewCustomerNumbers] = useState('');
    const [userAdded, setUserAdded] = useState('');
    const [userAddedError, setUserAddedError] = useState('');


    const fetchUsers = useCallback(async () => {
        setLoadingUsers(true);
        const token = await authService.getAccessToken();
        const response = await fetch('api/Admin/GetManagers', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setUsers(data);
        setLoadingUsers(false);
    }, [])

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers])

    const handleSubmit = async e => {
        e.preventDefault();
        console.log(`Submit ${newUserEmail} - ${newUserRole}`);

        let groupCustomerNumbers = null;
        if (newUserRole === 'GroupCustomer' && newCustomerNumbers !== '') {
            groupCustomerNumbers = newCustomerNumbers.replace(/ /g, '').split(',');
        }

        const token = await authService.getAccessToken();
        const newUser = { email: newUserEmail, role: newUserRole, groupCustomerNumbers };
        try {
            const response = await fetch('api/Admin/AddNewUser', {
                method: 'POST',
                body: JSON.stringify(newUser),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            //console.log(JSON.stringify(data));
            if (!data.success) {
                throw new Error(data.result);
            }
            setUserAdded(newUserEmail);
            setUserAddedError(null);

            fetchUsers();
            //console.log(`User added ok ${userAdded} - ${userAddedError}`)
        } catch (error) {
            //console.log(`User added failed ${JSON.stringify(error)} ${userAdded} - ${userAddedError}`)
            setUserAdded('');
            setUserAddedError(error.message);
        }
    }

    const deleteUser = async email => {
        const token = await authService.getAccessToken();
        const reportQuery = { email };
        const url = `api/Admin/DeleteUser?${queryString.stringify(reportQuery)}`;

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Anrop misslyckades. ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
                throw new Error(data.result);
            }

            fetchUsers();
            console.log(`User deleted ok ${email}`)
        } catch (error) {
            console.log(`User delete failed ${error.message}`)
        }
    }

    return (
      <div>
            <h1>Admin</h1>
            <AddAdminComponent
                handleSubmit={handleSubmit}
                newUserEmail={newUserEmail}
                newUserRole={newUserRole}
                newCustomerNumbers={newCustomerNumbers}
                setNewUserEmail={setNewUserEmail}
                setNewUserRole={setNewUserRole}
                setNewCustomerNumbers={setNewCustomerNumbers}
                userAdded={userAdded}
                userAddedError={userAddedError}
            />

            <div className='vertical-space' />
            <h4>Befintliga användare</h4>
            <div>
                {
                    loadingUsers
                        ? <BeatLoader size={15} margin={2} />
                        : users.map(u => <ExistingAdminComponent key={u.email} user={u} deleteUser={deleteUser} />)
                }
            </div>
      </div>
    );
}

function AddAdminComponent({ handleSubmit, newUserEmail, newUserRole, newCustomerNumbers, setNewUserEmail, setNewUserRole, setNewCustomerNumbers, userAdded, userAddedError }) {
    return (
            <div><h4>Lägg till användare</h4>
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="addNewEmail">Epost:</Label>
                <Input
                    type="email"
                    name="email"
                    value={newUserEmail}
                    onChange={e => setNewUserEmail(e.target.value)}
                    id="addNewEmail"
                    placeholder="Ange epost-adress" />
            </FormGroup>
            <FormGroup>
                <Label for="addNewRole">Roll:</Label>
                <Input
                    type="select"
                    name="select"
                    value={newUserRole}
                    onChange={e => setNewUserRole(e.target.value)}
                    id="addNewRole">
                    <option value="Manager">Manager</option>
                    <option value="Admin">Admin</option>
                    <option value="GroupCustomer">Koncernanvändare</option>
                </Input>
            </FormGroup>
                {newUserRole === "GroupCustomer" &&
                    <FormGroup>
                        <Label for="addNewCustomerNumbers">Kundnr:</Label>
                        <Input
                            type="text"
                            name="customerNumbers"
                            value={newCustomerNumbers}
                            onChange={e => setNewCustomerNumbers(e.target.value)}
                            id="addNewCustomerNumbers"
                            placeholder="Ange kundnummer kommaseparerat (ex. 123,134,423)" />
                    </FormGroup>
                }
            <Button>Spara</Button>
        </Form>
        {userAdded && <div>Konto för {userAdded} har skapats.</div>}
        {userAddedError && <div>{userAddedError}</div>}
    </div>)
}

function ExistingAdminComponent({ user, deleteUser }) {

    const translateRole = role => {
        if (role === 'GroupCustomer') {
            return "Koncernanvändare";
        }
        return role;
    }

    return <div className='admin-user'>
        <Row>
            <Col xs={1}>Epost:</Col>
            <Col>{user.email}</Col>
            <Col>
                <Button className="float-right" onClick={() => deleteUser(user.email)}>Ta bort</Button>
            </Col>
        </Row>
        <Row>
            <Col xs={1}>Roll:</Col>
            <Col>{translateRole(user.role)}</Col>
        </Row>
        {user.groupCustomerNumbers !== null
            && user.groupCustomerNumbers.length > 0
            &&
            <Row>
                <Col xs={1}>Kundnr:</Col>
                <Col>{user.groupCustomerNumbers.join()}</Col>
            </Row>
        }
    </div>
}

export { AdminPage };
