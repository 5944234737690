import React from 'react';
import EnvironmentalAdvisors from './EnvironmentalAdvisors';
import { SupportContacts } from './SupportContacts';

function HazardousWastePage() {

    return (
        <div>
            <h1>Farligt avfall</h1>
            <SupportContacts title="Vid beställning kontakta ditt lokala kontor:" bigTitle noHede />

            <EnvironmentalAdvisors title="För rådgivning gällande farligt avfall kontakta din miljörådgivare:" bigTitle />

        </div>
    );
}

export default HazardousWastePage;
